<template>
  <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.62818 9.86545L4.81409 13.6795L1 9.86545" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
      d="M13.2084 1.4744H7.86866C7.05941 1.4744 6.2833 1.79587 5.71108 2.36809C5.13886 2.94032 4.81738 3.71642 4.81738 4.52567V13.6795"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.8086 5.28849L24.6227 1.4744L28.4368 5.28849" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
      d="M16.2319 13.6795H21.5717C22.3809 13.6795 23.157 13.358 23.7292 12.7858C24.3015 12.2135 24.6229 11.4374 24.6229 10.6282V1.47437"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "SortIcon"
}
</script>

<style scoped>

</style>
