<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.62842 15.8672L12.2859 13.6373V8.98438" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9998 22.004C7.15675 22.0057 3.17994 18.1762 2.99907 13.3365C2.81819 8.49681 6.49806 4.38108 11.3277 4.02134C16.1574 3.6616 20.4064 7.18673 20.9445 11.9998" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.3361 18.3354L18.6694 20.0031L17.6689 19.0027" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0035 23.0043H17.0018C15.8968 23.0043 15.001 22.1085 15.001 21.0035V17.0018C15.001 15.8968 15.8968 15.001 17.0018 15.001H21.0035C22.1085 15.001 23.0043 15.8968 23.0043 17.0018V21.0035C23.0043 22.1085 22.1085 23.0043 21.0035 23.0043Z" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</template>

<script>
export default {
  name: "ClockIcon"
}
</script>

<style scoped>

</style>
