<template>
  <div class=" h-56 items-center justify-end  w-1/7  flex flex-col">
    <div :class="[DataHeight, DataColor]" class="rounded-full w-4"></div>
    <span
      :class="DataDay === this.Currentday ? 'text-customOrange font-bold' : ' '"
      class=" font-light text-sm text-darkViolet">{{this.Day}}</span>
  </div>
</template>

<script>
export default {
  name: "ChartData",

  props: {
    datas: Object,
  },
  data() {
    return {
      vals: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
      DataHeight: "h-20",
      DataColor: "bg-gray-300",
      Day: "",
      Currentday: "",
    };
  },

  methods: {
    heights() {
      var d = new Date();
      var t = d.getDay();
      switch (t) {
        case 0:
          this.Currentday = "Su";
          break;
        case 1:
          this.Currentday = "Mo";
          break;
        case 2:
          this.Currentday = "Tu"; //
          break;
        case 3:
          this.Currentday = "We";
          break;
        case 4:
          this.Currentday = "Th";
          break;
        case 5:
          this.Currentday = "Fr";
          break;
        case 6:
          this.Currentday = "Sa";
          break;
      }

      if (this.datas.workout.completed == false) {
        this.DataColor = "bg-gray-300";
      } else {
        this.DataColor = "bg-gradient-to-r from-customOrange to-customRed";
      }

      switch (this.datas.day) {
        case "Su":
          this.Day = "S";
          break;
        case "Mo":
          this.Day = "M";
          break;
        case "Tu":
          this.Day = "T"; //
          break;
        case "We":
          this.Day = "W";
          break;
        case "Th":
          this.Day = "T";
          break;
        case "Fr":
          this.Day = "F";
          break;
        case "Sa":
          this.Day = "S";
          break;
      }
      switch (this.datas.workout.finished_exercises) {
        case 0:
          this.DataHeight = "h-0";
          break;
        case 1:
          this.DataHeight = "h-4";
          break;
        case 2:
          this.DataHeight = "h-10"; 
          break;
        case 3:
          this.DataHeight = "h-16";
          break;
        case 4:
          this.DataHeight = "h-20";
          break;
        case 5:
          this.DataHeight = "h-30";
          break;
        case 6:
          this.DataHeight = "h-32";
          break;
        case 7:
          this.DataHeight = "h-35";
          break;
        case 8:
          this.DataHeight = "h-36"; 
          break;
        case 9:
          this.DataHeight = "h-48";
          break;
        case 10:
          this.DataHeight = "h-52";
          break;
        default:
          this.DataHeight = "h-20";
      }
    },
  },

  beforeMount() {
    this.heights();
  },

  computed: {
    DataDay() {
      return this.datas.day;
    },
  },
};
</script>

<style>
</style>