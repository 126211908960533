<template>
  <div id="tabs"
       :class="currentRoute === 'home' ? 'bg-transparent ' : ' bg-white'"
       class="fixed flex justify-center  bottom-0 w-full px-2 py-5 bg-transparent  ease-in-out  transition-transform transition-medium">

    <nav class="w-54 relative flex">
      <span
        class="absolute rounded-full px-15 h-10 bg-drounded-lg shadow ease-out transition-transform transition-medium"
        :class="background"
        :style="{ transform: `translateX(calc(66% * ${selected}))`}"></span>

      <ul class="flex w-full justify-center space-x-8 px-2 relative bg-lue-200">
        <li>
          <router-link to="/">
            <button type="button" @click="select(0)"
                    :aria-selected="selected === 0"
                    class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
              <HomeIcon :class="selected === 0 ? 'text-white' : 'text-gray-500'"
                        class="h-6 w-6 transition-all ease-out transition-medium"/>
              <span v-if="selected === 0"
                    :class="selected === 0 ? 'text-white' : 'text-gray-700'"
                    class=" font-bold ml-2 text-sm font-medium transition-all ease-out transition-medium">
                Home
              </span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/activity">
            <button :class="selected === 1 ? '-ml-3' : ''" type="button"
                    @click="select(1)" :aria-selected="selected === 1"
                    class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
              <div>
                <ThunderIcon
                  :class="selected === 1 ? 'text-white' : 'text-gray-500'"
                  class="h-6 w-6 transition-all ease-out transition-medium"/>
              </div>
              <span v-if="selected === 1"
                    :class="selected === 1 ? 'text-white' : 'text-gray-700'"
                    class="font-bold ml-2 text-sm font-medium transition-all ease-out transition-medium">
                Activity
              </span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/library">
            <button :class="selected === 2 ? '-ml-1' : ''" type="button"
                    @click="select(2)" :aria-selected="selected === 2"
                    class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
              <div>
                <VideoCamIcon
                  :class="selected === 2 ? 'text-white' : 'text-gray-500'"
                  class="h-6 w-6 transition-all ease-out transition-medium"/>
              </div>
              <span v-if="selected === 2"
                    :class="selected === 2 ? 'text-white' : 'text-gray-700'"
                    class="font-bold ml-2 text-sm font-medium transition-all ease-out transition-medium">
                Library
              </span>
            </button>
          </router-link>
        </li>

        <li>
          <router-link to="/user">
            <button :class="selected === 3 ? '-ml-2' : ''" type="button" @click="select(3)"
                    :aria-selected="selected === 3"
                    class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
              <div>
                <UserIcon
                  :class="selected === 3 ? 'text-white' : 'text-gray-500'"
                  class="h-6 w-6 transition-all ease-out transition-medium"/>
              </div>
              <span v-if="selected === 3"
                    :class="selected === 3 ? 'text-white' : 'text-gray-700'"
                    class="font-bold ml-2 text-sm font-medium transition-all ease-out transition-medium">
                User
              </span>
            </button>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import HomeIcon from "../svg/HomeIcon";
import ThunderIcon from "../svg/ThunderIcon";
import VideoCamIcon from "../svg/VideoCamIcon";
import UserIcon from "../svg/UserIcon";

export default {
  name: "Tabs",
  components: {UserIcon, VideoCamIcon, ThunderIcon, HomeIcon},
  data() {
    return {
      activeTab: "home",
      selected: 0,
      background: "bg-darkViolet",
    };
  },
  mounted() {
    if (this.$route.name) {
      this.activeTab = this.$route.name;
    }
  },
  methods: {
    select(i) {
      this.selected = i;
      switch (i) {
        case 0:
          this.background = "bg-darkViolet";
          break;
        case 1:
          this.background = "bg-customGreen";
          break;
        case 2:
          this.background = "bg-lightBlue";
          break;
        case 3:
          this.background = "bg-customYellow";
          break;
      }
    },
  },

  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  watch: {
    currentRoute() {
      if (this.currentRoute === "home") {
        this.select(0);
      }
      if (this.currentRoute === "activity") {
        this.select(1);
      }
    }
  }
};
</script>

<style scoped>
.active-background {
  position: absolute;
  opacity: 1;
  width: 30vw;
  height: 2.5rem;
  z-index: 2;
  border-radius: 84px;
  transition: all 0.2s ease;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-fastest {
  transition-duration: 75ms;
}

.transition-faster {
  transition-duration: 100ms;
}

.transition-fast {
  transition-duration: 150ms;
}

.transition-medium {
  transition-duration: 200ms;
}

.transition-slow {
  transition-duration: 300ms;
}

.transition-slower {
  transition-duration: 500ms;
}

.transition-slowest {
  transition-duration: 700ms;
}

.transition-all {
  transition-property: all;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-transform {
  transition-property: transform;
}

.focus-visible.focus-visible\:underline {
  text-decoration: underline;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}
</style>
