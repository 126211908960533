<template>
  <svg width="19" height="21" viewBox="0 0 19 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8118 8.87682L10.3314 1.23151C10.2413 1.14057 10.1339 1.06839 10.0157 1.01914C9.89749 0.969879 9.77067 0.944519 9.64259 0.944519C9.51451 0.944519 9.3877 0.969879 9.26947 1.01914C9.15124 1.06839 9.04393 1.14057 8.95374 1.23151L1.47336 8.88652C1.29235 9.06899 1.1494 9.2856 1.0528 9.52377C0.956197 9.76194 0.907874 10.0169 0.910634 10.2739V18.4044C0.909883 18.901 1.09961 19.3791 1.44074 19.7401C1.78187 20.101 2.24843 20.3175 2.74435 20.3448H16.5408C17.0368 20.3175 17.5033 20.101 17.8444 19.7401C18.1856 19.3791 18.3753 18.901 18.3745 18.4044V10.2739C18.3753 9.75286 18.1735 9.25189 17.8118 8.87682ZM7.70216 18.4044V12.5831H11.583V18.4044H7.70216ZM16.4341 18.4044H13.5235V11.6128C13.5235 11.3555 13.4212 11.1087 13.2393 10.9268C13.0573 10.7448 12.8106 10.6426 12.5532 10.6426H6.73194C6.47462 10.6426 6.22784 10.7448 6.04589 10.9268C5.86394 11.1087 5.76172 11.3555 5.76172 11.6128V18.4044H2.85107V10.2351L9.64259 3.29807L16.4341 10.2739V18.4044Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: "HomeIcon"
  }
</script>

<style scoped>

</style>
