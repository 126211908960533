<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3858 8.99854H20.4472C21.3076 8.99854 22.0039 9.69483 22.0039 10.5552V20.4483C22.0039 21.3077 21.3076 22.004 20.4472 22.004H9.5547C8.69434 22.004 7.99805 21.3077 7.99805 20.4473V19.0027" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.99785 19.0027H16.3146C16.9838 19.0027 17.6081 18.6685 17.9793 18.1113L18.7136 17.0088C19.1517 16.3516 19.3858 15.5792 19.3858 14.7889V6.99766C19.3858 5.8922 18.4905 4.99683 17.385 4.99683H7.38084C6.27538 4.99683 5.38001 5.8922 5.38001 6.99766V14.0566C5.38001 14.6779 5.23495 15.2901 4.95783 15.8463L4.10348 17.5551C3.77034 18.2203 4.25454 19.0027 4.99785 19.0027Z" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6518 10.592L11.8376 13.4062L10.1489 11.7185" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.37863 3.99634V5.99717" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.3811 3.99634V5.99717" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "CalendarIcon"
}
</script>

<style scoped>

</style>
