<template>
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0834 19H7.91675" stroke="#7E6A97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.0001 30.0833L7.91675 19L19.0001 7.91666" stroke="#7E6A97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:"BackIcon"
}
</script>
