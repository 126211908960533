<template>
  <button
    class="shadow-orange font-bold rounded-full w-full flex justify-center items-center  text-white  bg-gradient-to-r from-customOrange to-customRed"
    @click="$emit('click')"
  >
    {{text}}
  </button>
</template>

<script>
export default {
  name: "MainButton",

  props: {
      text: {
          type: Text,
          default: "start"
      },
  },
};
</script>
