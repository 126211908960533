<template>
    <router-link :to="{ name:'showLibrary', params:{ id:item.id } }"
                 v-bind:style="{ 'background-image': 'url(' + thumbnail + ')' }"
                 class="w-full h-32 flex items-end  rounded-lg p-2 bg-center bg-cover bg-no-repeat shadow-md">
        <span class="text-white text-xs font-bold">{{ item.title }}</span>
    </router-link>
</template>
<script>
export default {
    name: 'LibraryItem',

    props: {
        item: Object
    },

    computed: {
        thumbnail: function() {
            return this.item.long_video_thumbnail !== null ? this.item.long_video_thumbnail_url : '/images/default-thumb.png';
        }
    }
}
</script>
