import axios from 'axios'
import { WORKOUTS_GENERATE, WORKOUTS_STORE, WORKOUTS_UPDATE, WORKOUT_EXERCISE_UPDATE } from '@/utils/Routes';

export default {
  namespaced: true,

  state: {
    start: false,

    exercises: [],

    params: null,

    type: null,

    currentExerciseIndex: 0,

    currentSet: 1,

    id: null,
  },

  mutations: {
    SET_WORKOUT(state, { exercises, type, params }) {
      state.exercises = exercises
      state.type = type
      state.params = params
    },

    SET_START(state, payload) {
      state.start = payload.start;
    },

    SET_ID(state, payload) {
      state.id = payload.id
    },

    SET_CURRENT_EXERCISE_INDEX(state, index) {
      state.currentExerciseIndex = index;
    }
  },

  actions: {
    async fetchWorkout({commit}, payload) {
      let response = await axios.get(WORKOUTS_GENERATE, {
        params: payload,
      });

      if(response.data.success === true) {
        commit('SET_WORKOUT', response.data)
      } else {
        throw { errors: response.data.errors }
      }
    },

    async storeWorkout({commit}, payload) {
      let response = await axios.post(WORKOUTS_STORE, payload);

      if(response.data.success === true) {
        commit('SET_ID', response.data.data)
      } else {
        throw { errors: response.data.errors }
      }
    },

    async updateWorkout({state}, payload) {
      let endpoint = WORKOUTS_UPDATE
      let response = await axios.put(endpoint.replace('{workout}', state.id), payload);

      if(response.data.success !== true) {
        return Promise.reject()
      }

      return Promise.resolve()
    },

    async completeExercise({state}, exercisId) {
      let endpoint = WORKOUT_EXERCISE_UPDATE
      endpoint = endpoint.replace('{workout}', state.id)
      endpoint = endpoint.replace('{exercise}', exercisId)

      let response = await axios.put(endpoint, {
        completed: true,
      })

      if(response.data.success !== true) {
        throw { errors: response.data.errors }
      }
    },
  },

  getters: {
    workout(state) {
      return {
        params: state.params,
        type: state.type,
        exercises: state.exercises,
      }
    }
  },
}
