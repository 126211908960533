import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'

import auth from './modules/auth'
import workout from './modules/workout'
import onboarding from './modules/onboarding'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    weekProgress: [
      {
        day: 'Su',
        weekday: 0,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
      {
        day: 'Mo',
        weekday: 1,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
      {
        day: 'Tu',
        weekday: 2,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
      {
        day: 'We',
        weekday: 3,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
      {
        day: 'Th',
        weekday: 4,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
      {
        day: 'Fr',
        weekday: 5,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
      {
        day: 'Sa',
        weekday: 6,
        workout: {
          completed: true,
          finished_exercises: 2,
          exercises: 2,
        },
      },
    ],

    librarySort: null,
    library: [],
    badges: [],
    allbadges: []
  },

  mutations: {
    SET_LIBRARY(state, data) {
      state.library = data
    },

    SET_BADGES(state, data) {
      state.badges = data
    },
    SET_ALLBADGES (state, data) {
      state.allbadges = data
    },
  },

  actions: {
    fetchLibrary({commit}, payload) {
      return axios.get('/exercises', {
        params: payload,
      }).then(response => {
        commit('SET_LIBRARY', response.data.data)
      }).catch(() => {
        //
      })
    },

    fetchBadges({ commit }) {
      return axios
        .get('/badges')
        .then(response => {
          commit('SET_BADGES', response.data.data)
        })
        .catch(() => {
          //
        })
    },

    fetchAllBadges({ commit }) {
      return axios
        .get('/badges/all')
        .then(response => {
          commit('SET_ALLBADGES', response.data.data)
        })
        .catch(() => {
          //
        })
    }
  },

  modules: {
    auth,
    workout,
    onboarding
  }
})
