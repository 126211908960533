// Auth routes
export const SIGN_IN_ENDPOINT = '/auth/login';
export const SIGN_UP_ENDPOINT = '/auth/register';
export const PASSWORD_RESET_TOKEN_ENDPOINT = '';
export const PASSWORD_REQUEST_ENDPOINT = '';
export const VERIFY_EMAIL_ENDPOINT = '/auth/verify-email/{code}';
export const USER_ENDPOINT = '/auth/user';
export const USER_PICTURE_ENDPOINT = '/auth/user/picture';
export const USER_STATS_ENDPOINT = '/auth/user/stats';
export const USER_BADGE_COLLECTOR_ENDPOINT = '/auth/user/collect-badge';
export const WORKOUTS_GENERATE = '/workouts/generate';
export const WORKOUTS_STORE = '/workouts';
export const WORKOUTS_UPDATE = '/workouts/{workout}';
export const WORKOUT_EXERCISE_UPDATE = '/workouts/{workout}/exercise/{exercise}';
export const STORE_CELEBRATION_ENDPOINT = '/celebration-logs';
export const HELP_REQUEST_ENDPOINT = '/help-request';
