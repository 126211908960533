<template>
  <ion-header>
    <div :class="[{'flex-col items-start justify-end':col === true, 'items-end justify-between':col === false}, bg]"
         class="pt-8 hlg:pt-12 px-5 pb-5 flex">
      <slot name="button"></slot>
      <h2 class="mb-8 text-4xl">{{ title }}</h2>
      <slot name="sort"></slot>
    </div>
  </ion-header>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
    bg: String,
    col: {type: Boolean, default: true}
  },
}
</script>
<style scoped>
ion-header {
  padding-top: var(--ion-safe-area-top, 0);
  background: #fff;
}

h2 {
  @apply text-white !important;
}
</style>
