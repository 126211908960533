<template>
  <div class="relative pb-16 px-2 w-full">
    <div class="justify-between items-end absolute flex py-2 w-10/12 px-4 ml-10">
      <ChartData v-for="item in progress" :key="item.id" :datas="item" />
    </div>

    <div v-for="val in vals" :key="val.id" class="flex items-center px-5">
      <span class="mr-2 font-light text-sm text-darkViolet">{{ val }}</span>
      <hr class="w-full border-dashed">
    </div>
  </div>
</template>

<script>
import ChartData from "@/components/activity/ChartCard/partials/ChartData.vue";

export default {
  name: "Charts",
  props: {
    progress: Array,
  },
  data() {
    return {
      vals: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
    };
  },
  components: {
    ChartData,
  },
  methods: {
    name() {},
  },
};
</script>
