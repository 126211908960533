<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6425 10.6724C12.4101 10.6724 13.1604 10.4448 13.7986 10.0183C14.4368 9.59191 14.9343 8.9858 15.228 8.27666C15.5217 7.56753 15.5986 6.78721 15.4488 6.0344C15.2991 5.28158 14.9295 4.59008 14.3867 4.04733C13.844 3.50458 13.1525 3.13496 12.3996 2.98522C11.6468 2.83547 10.8665 2.91233 10.1574 3.20606C9.44825 3.49979 8.84214 3.99721 8.4157 4.63542C7.98927 5.27363 7.76166 6.02395 7.76166 6.79152C7.76166 7.82079 8.17053 8.8079 8.89834 9.53571C9.62614 10.2635 10.6133 10.6724 11.6425 10.6724ZM11.6425 4.85108C12.0263 4.85108 12.4015 4.96489 12.7206 5.1781C13.0397 5.39132 13.2884 5.69438 13.4353 6.04894C13.5821 6.40351 13.6206 6.79367 13.5457 7.17008C13.4708 7.54648 13.286 7.89224 13.0146 8.16361C12.7432 8.43499 12.3975 8.6198 12.0211 8.69467C11.6447 8.76954 11.2545 8.73111 10.9 8.58425C10.5454 8.43738 10.2423 8.18867 10.0291 7.86957C9.8159 7.55046 9.70209 7.1753 9.70209 6.79152C9.70209 6.27688 9.90653 5.78332 10.2704 5.41942C10.6343 5.05552 11.1279 4.85108 11.6425 4.85108Z"
      fill="currentColor"/>
    <path
      d="M11.6425 12.6128C9.84131 12.6128 8.11386 13.3284 6.8402 14.602C5.56655 15.8757 4.85101 17.6031 4.85101 19.4043C4.85101 19.6617 4.95323 19.9084 5.13518 20.0904C5.31713 20.2723 5.56391 20.3746 5.82123 20.3746C6.07855 20.3746 6.32533 20.2723 6.50728 20.0904C6.68923 19.9084 6.79145 19.6617 6.79145 19.4043C6.79145 18.1178 7.30254 16.8839 8.2123 15.9741C9.12206 15.0644 10.3559 14.5533 11.6425 14.5533C12.9291 14.5533 14.163 15.0644 15.0728 15.9741C15.9825 16.8839 16.4936 18.1178 16.4936 19.4043C16.4936 19.6617 16.5958 19.9084 16.7778 20.0904C16.9597 20.2723 17.2065 20.3746 17.4638 20.3746C17.7212 20.3746 17.9679 20.2723 18.1499 20.0904C18.3318 19.9084 18.4341 19.6617 18.4341 19.4043C18.4341 17.6031 17.7185 15.8757 16.4449 14.602C15.1712 13.3284 13.4438 12.6128 11.6425 12.6128Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: "UserIcon"
  }
</script>

<style scoped>

</style>
