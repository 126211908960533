<template>
  <svg width="17" height="23" viewBox="0 0 17 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.77911 22.315C7.6666 22.3149 7.55498 22.2952 7.44923 22.2568C7.24524 22.1821 7.07222 22.0411 6.95787 21.8564C6.84353 21.6717 6.79448 21.454 6.81859 21.2381L7.56566 14.3592H1.85108C1.67482 14.3595 1.50182 14.3117 1.35065 14.2211C1.19949 14.1304 1.07587 14.0003 0.993082 13.8447C0.910292 13.6891 0.871455 13.5139 0.880742 13.3379C0.89003 13.1619 0.94709 12.9917 1.04579 12.8457L8.70081 1.39711C8.82188 1.21795 8.99933 1.08437 9.20499 1.01757C9.41065 0.950778 9.63273 0.954599 9.83597 1.02843C10.0313 1.10097 10.1982 1.23449 10.3118 1.40923C10.4253 1.58397 10.4796 1.79064 10.4666 1.99865L9.71954 8.926H15.4341C15.6104 8.92574 15.7834 8.97349 15.9345 9.06414C16.0857 9.15478 16.2093 9.2849 16.2921 9.4405C16.3749 9.59611 16.4137 9.77133 16.4045 9.94734C16.3952 10.1234 16.3381 10.2935 16.2394 10.4395L8.58439 21.8881C8.49539 22.0198 8.37541 22.1276 8.23499 22.202C8.09458 22.2764 7.93803 22.3152 7.77911 22.315ZM3.66538 12.4188H8.6426C8.77799 12.4191 8.91181 12.4478 9.03546 12.5029C9.15911 12.558 9.26985 12.6385 9.36056 12.739C9.45212 12.8408 9.52107 12.9609 9.56287 13.0914C9.60468 13.2218 9.61839 13.3596 9.60312 13.4957L9.16652 17.5221L13.6198 10.8664H8.6426C8.50569 10.8673 8.37015 10.8391 8.2449 10.7838C8.11965 10.7285 8.00752 10.6474 7.91587 10.5456C7.82423 10.4439 7.75516 10.324 7.71319 10.1936C7.67123 10.0633 7.65732 9.92558 7.67238 9.7895L8.10898 5.76309L3.66538 12.4188Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: "ThunderIcon"
  }
</script>

<style scoped>

</style>
