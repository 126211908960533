<template>
  <div class="flex flex-col justify-center px-2 text-darkViolet">
    <span class=" font-light text-xs">{{title}}</span>
    <div class="flex ml-1 items-center">
      <slot></slot>
      <span class=" font-bold text-4xl ml-1">{{number}}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: "ActivityItem",
  props: {
    number: Number,
    title: String,
  },
};
</script>
<style scoped>

</style>
