import Library from "../views/library/Library";

const routes = [
  {
    path: '/library',
    name: 'library',
    component: Library
  },
  {
    path: '/library/:id',
    name: 'showLibrary',
    component: () => import( '../views/library/MoreInfo.vue')
  },
]

export default routes;
