<template>
  <ion-page>
    <ion-content>
      <div class="px-6 space-y-4">
        <router-link class="flex items-center mb-5 mt-14" to="/activity">
          <BackIcon class="mr-4" />
          <h1 class=" font-bold text-xl">My Badges</h1>
        </router-link>
        <template v-if="!loading">
          <BadgeComponent v-for="item in allBadges" :key="item.id"
            :title="item.award" :badgesData="item" :data="item.completed_dfgs" :isCompleted="isBadgeCompleted(item)">
            <template>
              <div class="w-20">
                <img :src="item.icon_url" class="w-full" alt="">
              </div>
            </template>
          </BadgeComponent>
        </template>
        <template v-else>
          <div class="w-full flex justify-center items-center h-40">
            <ion-spinner color="dark" name="dots"></ion-spinner>
          </div>
        </template>
      </div>
    </ion-content>
  </ion-page>

</template>

<script>
import BadgeComponent from "@/components/activity/BadgeComponent.vue";
import BackIcon from "@/components/svg/BackIcon.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "Badges",
  components: {
    BadgeComponent,
    BackIcon,
  },

  data: () => ({
    loading: false
  }),

  mounted: async function() {
    // if(this.allBadges.length === 0) {
      this.loading = true;
      await this.fetchBadges();
      await this.fetchAllBadges();
      this.loading = false;
    // }
  },

  methods: {
    ...mapActions({
      fetchBadges: "fetchBadges",
      fetchAllBadges: "fetchAllBadges",
    }),

    isBadgeCompleted: function(item) {
      if(this.stats === null) {
        return false;
      }
      
      let isCompleted = this.stats.badges.find(badge => badge.id === item.id);
      return !!isCompleted;
    }
  },

  computed: {
    ...mapState({
      allBadges: (state) => state.allbadges,
    }),
    stats() {
      return this.$store.state.auth.stats;
    },
  }
}
</script>
<style >
.gray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
</style>