import onboarding from '../views/onboarding/onboarding';

const routes = [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: onboarding
  },
  {
    path: '/onboarding/step-1',
    name: 'StepOne',
    component: () => import( '../views/onboarding/StepOne')
  },
  {
    path: '/onboarding/step-2',
    name: 'StepTwo',
    component: () => import( '../views/onboarding/StepTwo')
  },
  {
    path: '/onboarding/step-3',
    name: 'StepThree',
    component: () => import( '../views/onboarding/StepThree')
  },
  {
    path: '/onboarding/step-4',
    name: 'StepFour',
    component: () => import( '../views/onboarding/StepFour')
  },
  {
    path: '/onboarding/step-5',
    name: 'StepFive',
    component: () => import( '../views/onboarding/StepFive')
  },
  {
    path: '/onboarding/step-6',
    name: 'StepSix',
    component: () => import( '../views/onboarding/StepSix')
  },
]
export default routes;