import authMiddleware from '@/middlewares/authMiddleware';

const routes = [
    {
        path: '/sign-in',
        name: 'signIn',
        component: () => import('@/views/auth/SignIn')
    },
    // {
    //     path: '/forgot-password',
    //     name: 'ForgotPassword',
    //     component: () => import('@/pages/auth/ForgotPassword')
    // },
    // {
    //     path: '/reset-password',
    //     name: 'ResetPassword',
    //     component: () => import('@/pages/auth/ResetPassword')
    // },
    {
        path: '/verify-email',
        name: 'verifyEmail',
        component: () => import('@/views/auth/VerifyEmail'),
        beforeEnter: authMiddleware
    },
    {
      path: '/sign-up',
      name: 'signUp',
      component: () => import( '../views/auth/SignUp'),
      children: [
        {
          path: '/welcome',
          name: 'signUpWelcome',
          component: () => import( '../views/auth/SignUp/Welcome')
        },
        {
          path: '/name',
          name: 'signUpName',
          component: () => import('../views/auth/SignUp/Name')
        },
        {
          path: '/email',
          name: 'signUpEmail',
          component: () => import('../views/auth/SignUp/Email')
        },
        {
          path: '/password',
          name: 'signUpPassword',
          component: () => import('../views/auth/SignUp/Password')
        },
        {
          path: '/enable-health',
          name: 'signUpEnableHealth',
          component: () => import('../views/auth/SignUp/Health')
        },
        {
          path: '/pro-plan',
          name: 'signUpProPlan',
          component: () => import('../views/auth/SignUp/ProPlan')
        },
      ]
    }
]

export default routes;
