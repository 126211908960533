<template>
  <svg width="21" height="15" viewBox="0 0 21 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3746 2.93707C19.0798 2.80434 18.7525 2.76117 18.4334 2.81292C18.1143 2.86467 17.8174 3.00906 17.5797 3.22814L15.4937 5.16857V3.76176C15.4937 2.9898 15.187 2.24947 14.6412 1.70362C14.0953 1.15776 13.355 0.851105 12.583 0.851105H3.85108C3.07913 0.851105 2.33879 1.15776 1.79294 1.70362C1.24709 2.24947 0.94043 2.9898 0.94043 3.76176V11.5235C0.94043 12.2955 1.24709 13.0358 1.79294 13.5816C2.33879 14.1275 3.07913 14.4342 3.85108 14.4342H12.583C13.355 14.4342 14.0953 14.1275 14.6412 13.5816C15.187 13.0358 15.4937 12.2955 15.4937 11.5235V10.1167L17.5894 12.0571C17.8979 12.3364 18.2987 12.4919 18.7148 12.4937C18.9458 12.4932 19.1739 12.4436 19.3843 12.3482C19.6704 12.2324 19.9156 12.034 20.0884 11.7782C20.2612 11.5224 20.3539 11.2209 20.3545 10.9123V4.373C20.3531 4.06319 20.259 3.7609 20.0844 3.505C19.9097 3.2491 19.6625 3.05131 19.3746 2.93707ZM13.5533 11.5235C13.5533 11.7808 13.451 12.0276 13.2691 12.2095C13.0871 12.3915 12.8404 12.4937 12.583 12.4937H3.85108C3.59377 12.4937 3.34699 12.3915 3.16504 12.2095C2.98308 12.0276 2.88087 11.7808 2.88087 11.5235V3.76176C2.88087 3.50444 2.98308 3.25766 3.16504 3.07571C3.34699 2.89376 3.59377 2.79154 3.85108 2.79154H12.583C12.8404 2.79154 13.0871 2.89376 13.2691 3.07571C13.451 3.25766 13.5533 3.50444 13.5533 3.76176V11.5235ZM18.4043 10.1652L15.678 7.64263L18.4043 5.12006V10.1652Z" fill="currentColor"/>
  </svg>
</template>

<script>
    export default {
        name: "VideoCamIcon"
    }
</script>

<style scoped>

</style>
