<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.45628 20.8639C7.05206 21.0807 6.55962 21.0482 6.18737 20.7802C5.81512 20.5122 5.62814 20.0555 5.70555 19.6034L6.57391 14.5783L2.89638 11.0178C2.57136 10.6982 2.45541 10.222 2.59708 9.78875C2.73875 9.35547 3.11359 9.03981 3.56466 8.97394L8.64877 8.24064L10.9197 3.66473C11.1249 3.25491 11.5439 2.99609 12.0022 2.99609C12.4605 2.99609 12.8795 3.25491 13.0846 3.66473L15.3556 8.24064L20.4397 8.97394C20.8908 9.03981 21.2656 9.35547 21.4073 9.78875C21.5489 10.222 21.433 10.6982 21.108 11.0178L17.4284 14.5763L18.2968 19.6014C18.3751 20.0538 18.1885 20.5113 17.8161 20.7799C17.4436 21.0485 16.9506 21.0811 16.5461 20.8639L12.0002 18.4939L7.45628 20.8639Z" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.6679 11.667L11.584 13.7509L10.3325 12.5003" stroke="#FD5C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:"StarIcon"
}
</script>

<style>

</style>