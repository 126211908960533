import axios from 'axios'

import {
  SIGN_IN, SIGN_UP, SIGN_OUT, PASSWORD_REQUEST_TOKEN,
  PASSWORD_RESET, VERIFY_EMAIL, CHECK_USER, UPDATE_AREAS, STORE_CELEBRATION, HELP_REQUEST
} from '@/store/types/actions';

import {
  SET_USER, SET_FORM, CLEAR, UPDATE_USER
} from '@/store/types/mutations';

import {
  SIGN_IN_ENDPOINT, SIGN_UP_ENDPOINT, PASSWORD_RESET_TOKEN_ENDPOINT,
  PASSWORD_REQUEST_ENDPOINT, VERIFY_EMAIL_ENDPOINT, USER_ENDPOINT, 
  USER_STATS_ENDPOINT, USER_PICTURE_ENDPOINT, USER_BADGE_COLLECTOR_ENDPOINT, STORE_CELEBRATION_ENDPOINT, HELP_REQUEST_ENDPOINT
} from '@/utils/Routes';

export default {
  namespaced: true,

  state: {
    user: null,
    token: null,
    form: {
      name: '',
      email: '',
      password: '',
      confirm_password: ''
    },
    stats: null,
    newLevel: false,
  },

  mutations: {
    [SET_USER] (state, payload) {
      state.user = payload.user;
      state.token = payload.token;

      localStorage.setItem('user', JSON.stringify(payload.user));
      localStorage.setItem('token', JSON.stringify(payload.token));

      axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
    },

    [UPDATE_USER] (state, payload) {
      state.user = payload.user;
      localStorage.setItem('user', JSON.stringify(payload.user));
    },

    [SET_FORM] (state, payload) {
      state.form = { ...state.form, ...payload };
    },

    [CLEAR] (state) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');

      state.user = null;
      state.token = null;
      state.stats = null;
    },

    SET_STATS(state, payload) {
      if (state.stats && state.stats.level < payload.level) {
        state.newLevel = true
      }

      state.stats = payload
    },
  },

  actions: {
    async [SIGN_IN] ({ commit }, credentials) {
        let response = await axios.post(SIGN_IN_ENDPOINT, credentials);

        if(response.data.success === true) {
          commit(SET_USER, response.data)
          return Promise.resolve(true);
        } else {
          return Promise.reject(response.data.errors);
        }
    },

    /* ------------------------------------------------------------------------------------ */

    async [SIGN_UP] ({ state, commit }) {
      let response = await axios.post(SIGN_UP_ENDPOINT, state.form);

      if(response.data.success === true) {
        commit(SET_USER, response.data);
        return Promise.resolve(true)
      } else {
        return Promise.reject(response.data.errors)
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [PASSWORD_REQUEST_TOKEN] (payload) {
      let response = await axios.post(PASSWORD_REQUEST_ENDPOINT, payload);

      if(response.data.success === false) {
        throw { errors: response.data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [PASSWORD_RESET] (payload) {
      let endpoint = PASSWORD_RESET_TOKEN_ENDPOINT;
      let response = await axios.post(endpoint.replace('{token}', payload.token), payload);

      if(response.data.success === false) {
        throw { errors: response.data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [VERIFY_EMAIL] (state, payload) {
      let endpoint = VERIFY_EMAIL_ENDPOINT;

      let response = await axios.post(endpoint.replace('{code}', payload.code));

      if(response.data.success === true) {
          let user = JSON.parse(localStorage.getItem('user'));
          user.email_verified_at = 'verified';
          localStorage.setItem('user', JSON.stringify(user));
          return Promise.resolve(true)
      } else {
        return Promise.reject(false)
      }
    },

    /* ------------------------------------------------------------------------------------ */
    
    async sendVerificationCode() {
      let response = await axios.get('auth/verify-email/send-code');

      if(response.data.success === false) {
        throw { errors: response.data.errors };
      }

      return Promise.resolve(true)
    },

    /* ------------------------------------------------------------------------------------ */

    async [SIGN_OUT] ({ commit }) {
      await new Promise((resolve) => {
        setTimeout(() => {
          commit(CLEAR);
          resolve();
        }, 1000);
      })
    },

    /* ------------------------------------------------------------------------------------ */

    async [UPDATE_AREAS] ({ commit }, areas) {
      let { data } = await axios.put(`${USER_ENDPOINT}/areas`, { areas });


      if(data.success === true) {
        commit(UPDATE_USER, data)
      } else {
        throw { errors: data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [CHECK_USER] ({ commit }) {
      let { data } = await axios.get(USER_ENDPOINT);

      if(data.success === true) {
        commit(UPDATE_USER, data)
      } else {
        throw { errors: data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async [HELP_REQUEST] (_, payload) {
      let { data } = await axios.post(HELP_REQUEST_ENDPOINT, payload);

      if(data.success === false) {
        throw { errors: data.errors };
      }
    },
    
    /* ------------------------------------------------------------------------------------ */
      
    async [STORE_CELEBRATION] (_, celebrationPlatform) {
      let { data } = await axios.post(STORE_CELEBRATION_ENDPOINT, {platform : celebrationPlatform});

      if(data.success === false) {
        throw { errors: data.errors };
      }
    },

    /* ------------------------------------------------------------------------------------ */

    async updateUser({commit}, payload) {
      let { data } = await axios.put(USER_ENDPOINT, payload)

      if(data.success === true) {
        commit(UPDATE_USER, data)
      } else {
        throw { errors: data.errors };
      }
    },

    async uploadUserPicture({commit}, payload) {
      let { data } = await axios.post(USER_PICTURE_ENDPOINT, payload)

      if(data.success === true) {
        commit(UPDATE_USER, data)
        return data.user
      } else {
        throw { errors: data.errors };
      }
    },

    async fetchStats({commit}) {
      let { data } = await axios.get(USER_STATS_ENDPOINT)

      if(data.success === true) {
        commit('SET_STATS', data.data)
      } else {
        throw { errors: data.errors };
      }
    },

    async updateStats(context, payload) {
      let { data } = await axios.put(USER_STATS_ENDPOINT, payload)

      if(data.success !== true) {
        return Promise.reject()
      }

      return Promise.resolve()
    },

    async collectBadge(context, payload) {
      let { data } = await axios.post(USER_BADGE_COLLECTOR_ENDPOINT, {
        id: payload.id
      })

      if(data.success !== true) {
        return Promise.reject()
      }

      return Promise.resolve()
    },
    
    async storeUserDfgs(context, payload) {
      let { data } = await axios.post('/auth/user/dfgs', payload);

      if(data.success !== true) {
        return Promise.reject()
      }

      return Promise.resolve()
    }

  },
}
