import Activity from "../views/Activity";
import Badges from "../views/activity/Badges";

const routes = [
  {
    path: '/activity',
    name: 'activity',
    component: Activity
  },
  {
    path: '/badges',
    name: 'badges',
    component: Badges
  },
]

export default routes;
