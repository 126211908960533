import Vue from 'vue';
import axios from 'axios';
import Ionic from '@ionic/vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapMutations, mapActions } from 'vuex';
import { Plugins } from "@capacitor/core";

import App from './App.vue';
import router from './router';
import store from './store';

import { CHECK_USER, SIGN_OUT } from '@/store/types/actions';
import { SET_USER } from '@/store/types/mutations';
import { BASE_API_URL } from '../env';

import '@ionic/core/css/ionic.bundle.css';
import "./assets/css/app.css";

import './utilities/validate.js'

axios.defaults.baseURL = BASE_API_URL;
axios.defaults.withCredentials = true;

const { NativeAudio } = Plugins;

try {
  NativeAudio.preloadComplex({
    assetPath: "public/sounds/game-start-countdown.wav",
    assetId: "game-start-countdown",
    volume: 1.0,
    audioChannelNum: 1
  });

  NativeAudio.preloadComplex({
    assetPath: "public/sounds/stop-sound-exercise.wav",
    assetId: "stop-sound-exercise",
    volume: 1.0,
    audioChannelNum: 1
  });

  NativeAudio.preloadComplex({
    assetPath: "public/sounds/game-start-countdown-short.wav",
    assetId: "game-start-countdown-short",
    volume: 1.0,
    audioChannelNum: 1
  });
  
  NativeAudio.preloadComplex({
    assetPath: "public/sounds/win-a-bandge.wav",
    assetId: "win-a-bandge",
    volume: 1.0,
    audioChannelNum: 1
  });
  
  NativeAudio.preloadComplex({
    assetPath: "public/sounds/finish-one-daily-feel-good.wav",
    assetId: "finish-one-daily-feel-good",
    volume: 1.0,
    audioChannelNum: 1
  });
  
  NativeAudio.preloadComplex({
    assetPath: "public/sounds/when-you-exit-confeti-screen.wav",
    assetId: "when-you-exit-confeti-screen",
    volume: 1.0,
    audioChannelNum: 1
  });
} catch (ex) {
  console.log(ex);
}

Vue.use(Ionic);

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

/** VUE GLOBAL FILTERS */
Vue.filter('truncate', function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.config.productionTip = false

new Vue({
  store,
  router,

  created: async function() {
    try {
      const user = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if(user && token) {
        this.setUser({
          user: JSON.parse(user),
          token: JSON.parse(token),
        });

        await this.checkUser();
        await this.fetchStats();
      }
    } catch (ex) {
      this.signOut();
      this.$router.push('/sign-in');
    }

    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        this.signOut();
        this.$router.push('/sign-in');
      }
      return Promise.reject(error)
    });
  },

  methods: {
    ...mapMutations({
      setUser: `auth/${SET_USER}`
    }),

    ...mapActions({
      checkUser: `auth/${CHECK_USER}`,
      signOut: `auth/${SIGN_OUT}`,
      fetchStats: `auth/fetchStats`,
    }),
  },

  render: h => h(App)
}).$mount('#app')
