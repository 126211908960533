<template>
  <div :class="isHome === false ? 'px-6 pt-12 ' : null"
       class="rounded-t-xl bg-gradient-to-b from-white to-smoke w-full text-darkViolet">
    <slot name="header"></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "RoundedBigCard",
  props: {
    isHome: Boolean
  }
}
</script>

<style scoped>

</style>
