import { extend } from 'vee-validate';
import { required, email, confirmed, min_value } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} is required'
});
extend('email', email);
extend('min_value', min_value);
extend('confirmed', {
  ...confirmed,
  message: 'The Password and Password confirmation fields do not match'
});
extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
});
extend('max', {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ['length'],
  message: 'The {_field_} cannot exceed {length} characters'
});
extend('min_value', {
  validate(value, { min_value }) {
    return parseInt(min_value) < parseInt(value);
  },
  params: ['min_value'],
  message: 'Min {_field_} is {min_value}'
});
