<template>

  <div :class="{ 'opacity-50 text-gray-500 gray' : !isCompleted }"  class="flex items-start px-4 py-3 bg-white rounded-md shadow-xl ">
    <div class="mr-4">
      <slot>
      </slot>
    </div>
    <div>
      <h1 class=" font-black  text-darkPurple w-10/12 uppercase">{{title}}</h1>
      <p class=" text-xs">{{data}} DFG Completed</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "BadgeComponent",

  props: {
    title: String,
    data: Number,
    badgesData:Object,
    isCompleted: Boolean
  },
};

</script>
