<template>
  <!-- <div id="app"> -->
  <ion-app>
    <ion-vue-router />
    <Tabs v-if="shouldShowTabs"/>
  </ion-app>
  <!-- </div> -->
</template>

<script>
import { mapActions } from "vuex";
import Tabs from "@/components/shared/Tabs";
export default {
  components: {
    Tabs,
  },

  computed: {
    shouldShowTabs() {
      return ["home", "activity", "library", "showLibrary", "user"].includes(
        this.$route.name
      );
    },
  },

  methods: {
    ...mapActions({
      fetchBadges: "fetchBadges",
      fetchAllBadges: "fetchAllBadges",
    }),
  },

  mounted() {
    this.fetchBadges(),
    this.fetchAllBadges()
  },
};
</script>
