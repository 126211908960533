<template>
  <div
    class="flex justify-between items-center rounded-lg bg-white px-4 py-3 w-11/12">
    <div class="flex items-center">
      <BadgesIcon class="mr-4" />
      <div class="flex flex-col">
        <p class=" text-darkViolet"><span class="font-bold ">{{badges.length}}</span>/13 </p>
        <span class="text-sm text-darkViolet">Badges Earned</span>
      </div>

    </div>
    <router-link to="/badges">
      <span class="text-sm font-bold text-darkViolet">See all</span>
    </router-link>
  </div>
</template>

<script>
import BadgesIcon from "@/components/svg/BadgesIcon.vue";
export default {
  name: "BadgesRow",
  props: {
    badges: Array,
  },
  components: {
    BadgesIcon,
  },
};
</script>
