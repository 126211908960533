<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="50" height="50" rx="12" fill="#382156"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31 15C31.585 15 32.0451 15.5 31.9965 16.083L31.92 17H34C34.5523 17 35 17.4477 35 18V22C35 24.199 33.2255 25.9836 31.0303 25.9999C30.5483 27.7807 29.4913 29.3691 28 30.5132L27.999 33H32C32.5523 33 33 33.4477 33 34C33 34.5523 32.5523 35 32 35H18C17.4477 35 17 34.5523 17 34C17 33.4477 17.4477 33 18 33H21.999L22 30.514L21.767 30.3293C20.4031 29.2089 19.4274 27.6929 18.969 25.9999C16.8421 25.9832 15.1113 24.3082 15.0052 22.2048L15 22V18C15 17.4872 15.386 17.0645 15.8834 17.0067L16 17H18.079L18.0035 16.083C17.9549 15.5 18.415 15 19 15H31ZM29.9132 17H20.0868L20.6971 24.3239C20.861 26.2899 21.9132 28.0736 23.5547 29.1679C23.8329 29.3534 24 29.6656 24 30V33H26V30C26 29.6724 26.1605 29.3655 26.4296 29.1786L26.6474 29.0276C28.1753 27.923 29.1461 26.2051 29.3029 24.3239L29.9132 17ZM18.246 19H17V22C17 22.9891 17.7179 23.8104 18.6611 23.9714L18.246 19ZM33 22V19H31.753L31.3389 23.9714C32.2821 23.8104 33 22.9891 33 22Z" fill="white"/>
</svg>

</template>

<script>
export default {
name:"BadgeIcon"
}
</script>
