<template>
  <ion-page>
    <ion-content class="relative hide-overflow" :scroll-y="true">
      <div class=" overflow-scroll">
        <div class="flex divide divide-x  justify-between px-4 mt-16 mb-8">
          <ActivityItem :number="stats ? stats.total_dfgs : 0"
            title="DFG Completed">
            <template>
              <StarIcon />
            </template>
          </ActivityItem>

          <ActivityItem :number="stats ? stats.actual_day_streaks : 0" title="Day Active Streak">
            <template>
              <CalendarIcon />
            </template>
          </ActivityItem>

          <ActivityItem :number="stats ? stats.movement_minutes : 0" title="Moving Minutes">
            <template>
              <ClockIcon />
            </template>
          </ActivityItem>
        </div>
        <!--badge component-->
        <div class="flex justify-center mb-16">
          <BadgesRow :badges="stats ? stats.badges : []" />
        </div>

        <!--charts-->
        <div
          class="cardHeight absolute bottom-0 mb-20 w-full bg-white rounded-t-xl">
          <ChartsCard :progress="stats ? stats.week_progress : []" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import ActivityItem from "@/components/activity/ActivityItem";
import ChartsCard from "@/components/activity/ChartCard/index";
import StarIcon from "@/components/svg/StarIcon.vue";
import CalendarIcon from "@/components/svg/CalendarIcon.vue";
import ClockIcon from "@/components/svg/ClockIcon.vue";
import BadgesRow from "@/components/activity/BadgesRow.vue";

export default {
  name: "Activity",
  components: {
    ActivityItem,
    ChartsCard,
    StarIcon,
    CalendarIcon,
    ClockIcon,
    BadgesRow,
  },

  computed: {
    stats() {
      return this.$store.state.auth.stats;
    },
  },

  methods: {
    fetchUserStats() {
      this.$store.dispatch("auth/fetchStats");
    },
  },

  mounted() {
    this.fetchUserStats();
  },
};
</script>

<style scoped>
.hide-overflow {
  --overflow: hidden !important;
}
.cardHeight {
  height: 50%;
}
</style>


