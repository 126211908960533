<template>
  <ion-page>
    <ion-content>
      <!--Header-->
      <PageHeader :col="false" bg="bg-blue" title="Library">
        <template slot="sort">
          <button @click="sortLibrary" class="flex items-center justify-center rounded-full px-3 py-2 bg-darkBlue mb-8 text-white">
            <SortIcon class="mr-2"/>
            <span class="uppercase text-sm">Sort</span>
          </button>
        </template>
      </PageHeader>
      <!--Content-->
      <RoundedBigCard class="-mt-8 relative z-10" style="height: 78vh">
        <template slot="content">
          <template v-if="working">
            <div class="h-full flex items-center justify-center">
              <ion-spinner name="dots"></ion-spinner>
            </div>
          </template>

          <template v-else>
            <!-- <div class="h-full overflow-y-scroll"> -->
              <div id="libraryItems" class="grid grid-cols-2 gap-6">
                <template v-if="library.length">
                  <LibraryItem :item="item" v-for="(item, index) in library" :key="index"/>
                </template>

                <template v-else>
                  <div class="col-span-2 py-4 flex justify-center">
                    No exercises found.
                  </div>
                </template>
              </div>
            <!-- </div> -->
          </template>
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>

import PageHeader from "@/components/shared/PageHeader";
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import LibraryItem from "@/components/library/LibraryItem";
import SortIcon from "@/components/svg/SortIcon";
import {mapState} from "vuex";

export default {
  name: "Library",
  
  components: {SortIcon, LibraryItem, RoundedBigCard, PageHeader},

  data() {
    return {
      working: false,
    }
  },

  computed: {
    ...mapState({
      library: state => state.library,
    }),

    sort: {
      get() {
        return this.$store.state.librarySort
      },

      set(value) {
        this.$store.state.librarySort = value
      }
    },
  },

  methods: {
    async fetchLibrary() {
      await this.$store.dispatch('fetchLibrary', {sort: this.sort})
      
      this.working = false
    },

    sortLibrary() {
      return this.$ionic.actionSheetController
        .create({
          header: 'Sort by',
          buttons: [
            {
              text: 'Liked',
              handler: () => {
                this.sort = 'upvoted'
                this.working = true
                this.fetchLibrary()
              },
            },
            {
              text: 'By Name',
              handler: () => {
                this.sort = 'name'
                this.working = true
                this.fetchLibrary()
              },
            },
            {
              text: 'Recently Added',
              handler: () => {
                this.sort = 'recent'
                this.working = true
                this.fetchLibrary()
              },
            },
            {
              text: 'Recently Completed',
              handler: () => {
                this.sort = 'completed'
                this.working = true
                this.fetchLibrary()
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
            },
          ],
        })
        .then(a => a.present())
    },
  },

  mounted() {
    if (! this.library.length) {
      this.working = true
    }
    
    this.fetchLibrary()
  }
}
</script>

<style>
#libraryItems > *:last-child{
  margin-bottom: 8rem !important;
}
</style>

