import axios from 'axios';

export default {
    namespaced: true,

    state: {
        form: {
            when: null,
            why: [],
        }
    },

    mutations: {
        SET_WHEN(state, when) {
            state.form.when = when;
        },

        SET_WHY(state, why) {
            state.form.why = why;
        }
    },

    actions: {
        complete: async function({ state }) {
            const { data } = await axios.put('/auth/user/onboarding', state.form);
            
            if(!data.success) {
                throw { errors: data.errors };
            }
        }
    }
}