<template>
  <div class=" pt-4 px-4 w-full">
    <div class=" flex justify-center space-x-8">
      <!-- <button class="text-sm text-darkViolet">Daily</button> -->
      <button
        class=" shadow-lg rounded-full px-6 py-3 font-bold text-sm text-darkViolet">Weekly</button>
      <!-- <button class="text-sm text-darkViolet">Monthly</button> -->
    </div>
    <Charts :progress="progress"/>
  </div>
</template>

<script>
import Charts from "@/components/activity/ChartCard/partials/Charts.vue";
export default {
  name: "ChartsCard",
 props: {
    progress: Array,
  },
  components: {
    Charts,
  },
};
</script>
