export default (to, from, next) => {
  const user = localStorage.getItem('user');

  const loggedIn = user && localStorage.getItem('token');
  const emailVerified = user !== null ? JSON.parse(user).email_verified_at !== null : null;

  if(emailVerified === false && to.name !== 'verifyEmail'){
      next('/verify-email');
  }

  if (!loggedIn) {
    next('/sign-in');
  }

  if(user && !JSON.parse(user).onboarding_complete) {
    next('/onboarding');
  }

  next();
}
