import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue';

import authMiddleware from '@/middlewares/authMiddleware';

import authRoutes from './auth';
import libraryRoutes from './library';
import activityRoutes from './activity';
import onboardingRoutes from './onboarding';

Vue.use(IonicVueRouter);

export default new IonicVueRouter({
  mode: 'history',

  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home'),
      beforeEnter: authMiddleware
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/User'),
      beforeEnter: authMiddleware
    },
    {
      path: '*',
      redirect: '/home'
    },
    ...authRoutes,
    ...libraryRoutes,
    ...onboardingRoutes,
    ...activityRoutes
  ]
});

