<template>
  <div style="background-image: url('/images/running.jpg');"
    class="bg-no-repeat bg-cover bg flex flex-col justify-between items-center h-full py-16">
    <div>
      <DarkLogo />
    </div>

    
     <router-link class="w-8/12" :to="{ name: 'StepOne' }">
     <MainButton class="h-14" text="START"/>
     </router-link>
    
  </div>
</template>

<script>
import DarkLogo from "@/components/svg/DarkLogo.vue";
import MainButton from '@/components/shared/MainButton.vue';

export default {
  name: "onboarding",

  components: {
    DarkLogo,
    MainButton
  },
};
</script>
